@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --body-bg-color: #172031;
  --primary-color: #172031;
  --primary-color-light: #3b4559;
  --que-button-bg-color: #3b4559;
  --secondary-color: #ff7e26;
  --text-color: #3b4559;
  --white-color: #fff;
  --white-color-rgb: 255, 255, 255;
  --font: "Poppins", sans-serif;
}
* {
  margin: 0;
  box-sizing: border-box;
}
img {
  max-width: 100%;
  display: inline-block;
  margin-top: -1px;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  display: inline-block;
}
span {
  display: inline-block;
  margin-left: 1px;
}
body {
  scroll-behavior: smooth;
  font-size: 16px;
  color: var(--white-color);
  background-color: #f5f6f7;
  line-height: normal;
  font-weight: 400;
  font-family: var(--font);
}
.txt_center {
  text-align: center;
}
.flex_align_center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.js_between {
  justify-content: space-between;
}
.js_center {
  justify-content: center;
}
.js_evenly {
  justify-content: space-evenly;
}
.hide {
  display: none;
}
.atmequiz_game_header {
  max-width: 428px;
  margin: 0 auto;
  padding: 8px 16px 8px 14px;
  background: linear-gradient(180deg, #3b4559, #172031);
  box-shadow: 0 1px 1px 0 hsla(0, 0%, 100%, 0.19);
  justify-content: space-around;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  color: #fff;
}
.atmequiz_game_navbar_logo_menu .atmequiz_game_header_menu_icon {
  margin-right: 8px;
  margin-bottom: 6px;
}
.atmequiz_game_navbar_logo_menu .atmequiz_game_header_menu_icon img,
.atmequiz_game_navbar_logo_menu .atmequiz_game_header_back_arrow_icon img {
  margin-top: 4px;
  width: 36px;
  height: 24px;
}
.atmequiz_game_navbar_coin_notification .atmequiz_game_navbar_coin_div {
  width: auto;
  min-width: 80px;
  padding: 1px 16px 4px 8px;
  height: 32px;
  flex-wrap: nowrap;
  background: #3b4559;
  border-radius: 24px;
}
.atmequiz_game_header .atmequiz_game_header_wrap {
  width: 100%;
}
.atmequiz_game_header_logo img,
.atmequiz_game_footer_logo img {
  max-width: 133px;
}
.atmequiz_game_navbar_coin_div .atmequiz_game_navbar_coin_img {
  max-height: 24px;
}
.atmequiz_game_navbar_logo_menu .atmequiz_game_header_logo_div,
.atmequiz_game_header_logo_div .atmequiz_game_header_logo {
  max-height: 23px;
  margin-right: 35px;
}
.atmequiz_game_navbar_coin_img img {
  animation: coin_zoom 1s ease-in-out infinite alternate;
}
@keyframes coin_zoom {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.9);
  }
}
.atmequiz_game_navbar_coin_div .atmequiz_game_navbar_coin_content {
  padding-left: 8px;
  padding-top: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  color: var(--white-color);
}
.atmequiz_game_navbar_coin_content .atmequiz_game_navbar_coin_text {
  font-size: 10px;
  font-weight: 400;
  display: block;
}
.atmequiz_game_navbar_notifi_div .atmequiz_game_navbar_notifi_icon {
  width: 34px;
  height: 34px;
  justify-self: end;
  background: #172031;
  border-radius: 28px;
  padding: 8px;
  margin-left: 8px;
  position: relative;
  cursor: pointer;
  animation: shake 1.6s linear infinite;
}
@keyframes shake {
  0%,
  5%,
  15%,
  60%,
  100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  35% {
    transform: rotate(-15deg);
  }
  45% {
    transform: rotate(10deg);
  }
  55% {
    transform: rotate(-5deg);
  }
}

.atmequiz_game_page_body::after {
  width: 100%;
  background-color: #f5f6f7;
  background-image: url("../src/images/bg_quiz.jpg");
  height: auto;
  opacity: 0.05;
  min-height: 100vh;
  content: "";
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
}
.atmequiz_game_page_body .atmequiz_game_page_main {
  max-width: 428px;
  padding: 12px;
  margin: 0 auto;
  background-color: #172031;
  position: relative;
  z-index: 1;
}
.atmequiz_game_page_text_ad_div .atmequiz_page_span_text {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  text-transform: uppercase;
  display: block;
  text-align: center;
}
.atmequiz_game_page_add_text_gridbox .atmequiz_game_page_text_ad_div {
  width: 100%;
  margin-bottom: 16px;
  border-radius: 0;
  padding: 8px 16px 16px;
  background: 0 0;
  min-height: 321px;
}
.atmequiz_game_heading .atmequiz_game_title_text {
  font-size: 22px;
}
.atmequiz_game_heading .atmequiz_game_heading_desc {
  color: rgba(var(--white-color-rgb), 0.64);
}
.atmequiz_game_page_quick_start_box .atmequiz_game_heading {
  margin-top: 16px;
  margin-bottom: 30px;
}
.atmequiz_game_page_wrap .atmequiz_game_page_row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: #3b4559;
  border-radius: 16px;
  padding: 24px 8px;
  min-height: 240px;
  background-size: contain;
}
.atmequiz_game_page_row .atmequiz_main_page_que_button_box {
  width: auto;
  margin-top: -44px;
}
.atmequiz_main_page_que_button .atmequiz_game_page_que_button_div {
  border-radius: 50px;
  border: 4px solid #172031;
  background: #3b4559;
  padding: 0 16px;
  color: var(--white-color);
  height: 40px;
}
.atmequiz_game_page_que_button_div .atmequiz_game_que_text {
  font-size: 14px;
  line-height: 12px;
  padding-left: 8px;
}
.atmequiz_game_que_content_title .atmequiz_game_que_title_text {
  font-size: 16px;
  padding: 4px 0 12px;
  line-height: 20px;
  min-height: 58px;
}
.atmequiz_game_page_row .atmequiz_game_option_box {
  width: 100%;
}
.atmequiz_game_option_box .atmequiz_game_option_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.atmequiz_game_option_list li {
  width: 47%;
  margin-bottom: 12px;
  border-radius: 8px;
}
.atmequiz_game_option_list .atmequiz_option_list_item {
  width: 100%;
  min-height: 50px;
  height: 100%;
  padding: 8px;
  font-size: 14px;
  color: #3b4559;
  font-weight: 600;
  box-shadow: 0 1px 3px #3b4559;
  border: 0 solid #3b4559;
  border-radius: 8px;
  cursor: pointer;
  background: #fff;
}
.atmequiz_game_page_fanfact_content .atmequiz_game_title_text {
  font-size: 16px;
}
.atmequiz_game_page_fanfact_wrap .atmequiz_game_page_fanfact_content {
  margin-top: 16px;
  padding: 16px 8px;
  border-radius: 16px;
  background: #172031;
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 93px;
}
.atmequiz_game_page_fanfact_content .atmequiz_game_page_funfact_desc {
  font-size: 13px;
  line-height: 16px;
}
.atmequiz_game_content_box .atmequiz_game_title_text,
.atmequiz_game_contact_wrapper .atmequiz_game_contact_section_title {
  font-weight: 700;
  margin-bottom: 32px;
  position: relative;
  text-align: center;
}
.atmequiz_game_content_box .atmequiz_game_heading {
  padding: 16px 0 0;
}
.atmequiz_game_content_box .atmequiz_game_title_text::before,
.atmequiz_game_contact_wrapper .atmequiz_game_contact_section_title::before {
  content: "";
  height: 3px;
  background: #ff7e26;
  width: 150px;
  position: absolute;
  top: 42px;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.atmequiz_game_list li {
  line-height: 1.4;
  padding-bottom: 24px;
  padding-left: 40px;
  font-size: 15px;
  font-weight: 400;
  color: #3b4559;
  position: relative;
}
.atmequiz_game_list li:after {
  content: "";
  display: flex;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 5px;
  height: 14px;
  border: solid #3b4559;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.atmequiz_game_animation_with_play_div {
  color: #fff;
  position: relative;
  border-radius: 16px;
  background: #3b4559;
  margin-top: 0;
  padding: 16px 30px 8px;
  min-height: 200px;
  margin-bottom: 16px;
}
.atmequiz_game_play_animation_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.atmequiz_game_play_animation_box .atmequiz_game_play_animation_coin {
  width: 130px;
  height: 120px;
}
.atmequiz_game_play_coin_heading .atmequiz_game_play_coin_title_text {
  font-size: 22px;
  font-weight: 700;
  margin-top: 10px;
}
.atmequiz_game_play_coin_heading .atmequiz_game_play_coin_desc {
  text-align: center;
  margin-bottom: 12px;
  margin-top: 2px;
  font-size: 15px;
  color: #99acd2;
  line-height: 22px;
}
.atmequiz_game_play_button_box {
  width: 100%;
  height: 56px;
  margin-bottom: 16px;
  margin-top: 8px;
  line-height: 56px;
}
.atmequiz_game_play_button_box .atmequiz_game_play_btn {
  background: #ffb540;
  padding: 0 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.atmequiz_game_play_button_box .atmequiz_game_play_btn:after,
.atmequiz_game_quiz_cato_join_button_div .primary_button::after,
.score_button_after:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 22px;
  background-color: hsla(0, 0%, 100%, 0.2);
  transform: skewX(-15deg);
  opacity: 0.6;
  filter: blur(5px);
  left: -10%;
  top: 1px;
  animation: btn_animation 1.8s ease infinite;
}
@keyframes btn_animation {
  0% {
    opacity: 0.6;
    left: -10%;
  }
  66% {
    opacity: 1;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 100%;
  }
}
.atmequiz_game_row .atmequiz_game_tab_grid {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  white-space: nowrap;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  border-right: 1px solid #3b4559;
  position: relative;
  margin-top: 8px;
  margin-bottom: 16px;
  border-radius: 0 8px 8px 0;
  margin-left: -12px;
  scrollbar-width: thing;
}
.atmequiz_game_container .atmequiz_game_row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  min-height: 400px;
  align-content: flex-start;
}
.atmequiz_game_tab_grid .atmequiz_game_tab_grid_list {
  display: flex;
  gap: 6px;
}
.atmequiz_game_tab_grid_list .atmequiz_game_tab_text {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  transition: 0.6s;
  border-radius: 8px;
  border: 2px solid;
  border-radius: 40px;
  color: #737679;
}
.atmequiz_game_tab_grid_list .atmequiz_game_tab_text.active {
  color: #ffb540;
  border: 2px solid;
  border-radius: 40px;
  font-weight: 700
}
.atmequiz_game_tab_grid_list li:first-child {
  margin-left: 12px;
}
.atmequiz_game_row .atmequiz_game_tab_search_div {
  background: #172031;
  border-radius: 50px;
  min-width: 40px;
  max-height: 40px;
  margin-left: 10px;
  margin-top: 6px;
  cursor: pointer;
}
.atmequiz_game_row .atmequiz_quizlist_grid_box {
  width: 100%;
  display: none;
}
.atmequiz_game_row .atmequiz_quizlist_grid_box.tab-active {
  display: block;
}
.atmequiz_game_row .atmequiz_quizlist_grid_box .atmequiz_quizlist_card {
  border-radius: 8px;
  margin-bottom: 12px;
  width: 100%;
  min-height: 146px;
  background: #3b4559;
}
.atmequiz_quizlist_grid_box .atmequiz_quizlist_card_body {
  display: flex;
  flex-wrap: nowrap;
  padding: 12px;
}
.atmequiz_quizlist_card .atmequiz_quizlist_card_img,
.atmequiz_game_quiz_cato_detail_item .atmequiz_game_quiz_cato_img {
  width: 76px;
  background: #fff;
  border-radius: 16px;
  height: 76px;
  min-width: 76px;
}
.atmequiz_quizlist_card_body .atmequiz_quizlist_content {
  padding-left: 12px;
  width: 100%;
}
.atmequiz_quizlist_card_body .atmequiz_quizlist__card_name_live_div {
  width: 100%;
  text-transform: uppercase;
}
.atmequiz_quizlist_card_body .atmequiz_quizlist_name {
  font-size: 10px;
  font-weight: 600;
  color: #dfe1fc;
}
.atmequiz_quizlist_card_body .atmequiz_quizlist_card_line_text {
  color: #55e6c1;
  font-size: 10px;
  display: flex;
  position: relative;
  font-weight: 700;
}
.atmequiz_quizlist_card_body .atmequiz_quizlist_card_line_text:before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  color: #fff;
  background: #55e6c1;
  border-radius: 8px;
  margin-right: 6px;
  animation: live_animation 1s infinite;
  box-shadow: 0 0 2px 3px #55e6c1;
}
@keyframes live_animation {
  0% {
    box-shadow: 0 0 0 0 #55e6c1;
  }
  100% {
    box-shadow: 0 0 0 5px rgba(141, 183, 247, 0.09);
  }
}
.atmequiz_quizlist_card_title_coin_row .atmequiz_quizlist_card_title_text {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.atmequiz_quizlist_card_title_coin_row .atmequiz_quizlist_card_title_text {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.atmequiz_quizlist_card_body .atmequiz_quizlist_desc {
  font-size: 14px;
  color: #b6b3ff;
}
.atmequiz_quizlist_card_title_coin_row .atmequiz_quizlist_coin_img {
  max-width: 19px;
  max-height: 19px;
  margin-left: 6px;
  margin-top: -16px;
}
.atmequiz_quizlist_card .atmequiz_quizlist_card_footer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #6165b78c;
  font-size: 12px;
}
.atmequiz_quizlist_card_footer .atmequiz_quizlist_info {
  margin-right: 8px;
  color: #dfe1fc;
}
.atmequiz_quizlist_card_footer .atmequiz_quizlist_entry_text {
  margin-right: 4px;
}
.atmequiz_quizlist_card_footer .atmequiz_quizlist_info_coin_icon {
  margin-right: 6px;
  max-width: 16px;
  max-height: 18px;
  display: inline-block;
  vertical-align: bottom;
}
.atmequiz_quizlist_info .atmequiz_quizlist_coin_count {
  color: #f5f6ff;
}
.atmequiz_quizlist_card_footer .atmequiz_quizlist_play_button {
  font-size: 16px;
  padding: 7px 28.65px;
  font-weight: 600;
  text-shadow: #ffb540 !important;
  line-height: 20px;
  text-transform: uppercase;
  border-radius: 50px;
  background: #ffb540;
  color: var(--white-color);
  text-align: center;
}
.timer_baseTimer__circle__1YodV {
  fill: none;
  stroke: none;
}
.timer_baseTimer__pathElapsed__D6lCz {
  stroke-width: 7px;
  stroke: #172031;
}
.timer_baseTimer__pathElapsed {
  stroke-width: 10px;
  stroke: #3b4559;
  fill: #3b4559;
}
.timer_baseTimer__pathRemaining__PkIN0 {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: all 1s linear;
  fill-rule: nonzero;
  stroke: currentColor;
  color: #172031;
}
span.timer_seconds__2KixA {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}
.atmequiz_game_quiz_cato_detail_box .atmequiz_game_quiz_cato_detail_item {
  color: #fff;
  border-radius: 16px;
  background: #3b4559;
  padding: 16px 30px 8px;
  min-height: 200px;
  margin-bottom: 16px;
  margin-top: 17px;
}
.atmequiz_game_quiz_cato_detail_box2 .atmequiz_game_quiz_cato_detail_item2 {
  color: #fff;
  border-radius: 16px;
  background: #3b4559;
  min-height: 200px;
  margin-bottom: 16px;
  margin-top: 17px;
}
.atmequiz_game_quiz_cato_detail_item .atmequiz_game_quiz_cato_img {
  margin: 8px auto;
  animation: bounce_zoom 2s ease 1;
}
@keyframes bounce_zoom {
  0% {
    transform: scale(1) translateY(-200px);
  }
  50%,
  70% {
    transform: scale(0.9, 0.95) translateY(0);
  }
  57% {
    transform: scale(1) translateY(-10px);
  }
  /* 70% {
        transform: scale(.9, .95) translateY(0);
    } */
  80% {
    transform: scale(0.9, 0.95) translateY(-5);
  }
  90%,
  100% {
    transform: scale(1) translateY(0);
  }
}
.atmequiz_game_quiz_cato_prize_contact_row .primary_button {
  border-radius: 8px;
  margin-top: 8px;
}
.atmequiz_game_quiz_cato_contact_box .atmequiz_game_quiz_cato_name {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 400;
}
.atmequiz_game_quiz_cato_contact_box .atmequiz_game_quiz_cato_title {
  font-size: 20px;
}
.atmequiz_game_quiz_cato_title .atmequiz_game_quiz_cato_coin_img,
.atmequiz_game_cato_que_game_about .atmequiz_que_game_play_win_text img,
.atmequiz_game_played_score_grid .atmequiz_game_quiz_cato_coin_img,
.atmequiz_game_played_score_content_box .atmequiz_game_played_score_coin_img {
  max-width: 19px;
  max-height: 19px;
}
.atmequiz_game_quiz_cato_contact_box .atmequiz_game_quiz_cato_desc_box {
  font-size: 15px;
  color: #b6b3ff;
  line-height: 22px;
}
.atmequiz_game_quiz_cato_desc_box .atmequiz_game_quiz_cato_desc_text_info {
  margin-bottom: 12px;
  margin-top: 2px;
}
.atmequiz_game_quiz_cato_entry_free_coin img {
  max-width: 16px;
}
.primary_button,
.buttonBG {
  padding: 0 16px;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  background: #ffb540;
  line-height: 56px;
  margin-bottom: 16px;
  border: none;
}
.atmequiz_game_quiz_cato_join_button_div .primary_button {
  position: relative;
  overflow: hidden;
}
.second_button {
  background: #3b4559;
  font-size: 18px;
}
.atmequiz_game_quiz_cato_prize_contact_row {
  gap: 12px;
  flex-wrap: nowrap;
  margin: 8px 0;
}
.atmequiz_game_quiz_cato_prize_contact_button_div {
  max-width: 50%;
  width: 100%;
}
.atmequiz_game_quiz_cato_prize_contact_button_div .second_button {
  border-radius: 8px;
}

.atmequiz_game_prize_content_grid .atmequiz_game_prize_content_title {
  font-size: 18px;
}
.atmequiz_game_prize_detail_box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  max-width: 100%;
  margin: 0 auto;
  width: 428px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
}
.atmequiz_game_prize_detail_box .atmequiz_game_prize_content_grid {
  background: #3b4559;
  width: 100%;
  height: auto;
  padding: 30px;
  position: absolute;
  border-radius: 16px 16px 0 0;
  min-height: 200px;
  bottom: 0;
  z-index: 2;
}
.atmequiz_game_prize_content_grid .atmequiz_game_prize_report_box {
  margin: 24px 0 16px;
}
.atmequiz_game_prize_report_list_item .atmequiz_game_prize_list_row {
  border-bottom: 1px solid #5154938c;
  padding-bottom: 0;
  margin-top: 16px;
}
.atmequiz_game_prize_report_coin_div .atmequiz_game_prize_report_coin_text {
  font-weight: 700;
}
.atmequiz_game_prize_report_coin_div .atmequiz_game_prize_report_coin_icon {
  max-width: 16px;
  max-height: 16px;
  vertical-align: text-top;
  margin-top: -2px;
  margin-left: 3px;
}
.atmequiz_game_prize_report_list_item:last-child .atmequiz_game_prize_list_row {
  border-bottom: none;
}
.atmequiz_game_close_icon {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 11px;
  top: 10px;
  background: #172031;
  border-radius: 50px;
  padding: 11px;
}

.atmequiz_game_contact_wrapper .atmequiz_game_contact_grid {
  padding: 24px 8px 0;
}
.atmequiz_game_contact_wrapper .atmequiz_game_contact_section_title,
.atmequiz_game_cato_que_game_about .atmequiz_que_game_play_win_text {
  font-size: 22px;
}
.atmequiz_game_contact_wrapper .atmequiz_game_contact_section_title::before {
  width: 80px;
}
.atmequiz_game_contact_detail_box .atmequiz_game_contact_rule_list li::before,
.atmequiz_game_contact_rule_list .atmequiz_game_contact_sublist_box li::before {
  content: "";
  display: flex;
  position: absolute;
  top: 7px;
  left: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #3b4559;
}
.atmequiz_game_contact_detail_box .atmequiz_game_contact_rule_list > li {
  line-height: 1.4;
  padding-bottom: 24px;
  padding-left: 24px;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  color: #d8d7ff;
}
.atmequiz_game_contact_rule_list .atmequiz_game_contact_sublist_box li {
  padding: 0 0 0 24px;
  margin: 20px 0 0;
  position: relative;
}

.atmequiz_game_cato_que_page_wrap .atmequiz_game_cato_que_page_row {
  position: relative;
}
.atmequiz_game_cato_que_page_grid .atmequiz_game_que_page_volume_icon {
  position: absolute;
  right: 12px;
  top: 10px;
  background: #3b4559 url("../src/images/volume-white.svg") no-repeat top 8px
    left 8px;
  width: 40px;
  height: 40px;
  padding: 6px;
  border-radius: 30px;
}
.atmequiz_game_cato_que_game_about .atmequiz_game_cato_que_game_name {
  font-size: 14px;
  font-weight: bold;
  color: rgba(var(--white-color-rgb), 0.64);
}
.atmequiz_game_cato_que_page_grid .atmequiz_game_cato_que_game_about {
  margin-top: 24px;
  margin-bottom: 30px;
}
.atmequiz_game_que_page_card .atmequiz_game_que_page_card_head {
  padding: 16px;
  position: relative;
}
.atmequiz_game_que_page_card_head .atmequiz_game_que_page_card_correct_que,
.atmequiz_game_que_page_card_head .atmequiz_game_que_page_card_Incorrect_que {
  width: 60px;
  display: flex;
  line-height: 7px;
  font-weight: 700;
  font-size: 18px;
}
.atmequiz_game_que_page_card_head
  .atmequiz_game_que_page_card_correct_que::before,
.atmequiz_game_que_page_card_head
  .atmequiz_game_que_page_card_Incorrect_que::before {
  width: 15px;
  height: 6px;
  content: "";
  display: flex;
  border-radius: 8px;
  margin-right: 8px;
  background: #1fca83;
}
.atmequiz_game_que_page_card_head
  .atmequiz_game_que_page_card_Incorrect_que::before {
  background: #ef4242;
}
.atmequiz_game_que_page_card_head .atmequiz_game_que_page_card_Incorrect_que {
  justify-content: flex-end;
}
.atmequiz_game_que_page_card_time_count_row
  .atmequiz_game_que_card_timer_wrapper {
  position: absolute;
  width: 86px;
  height: 86px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
  background: #3b4559;
  top: -10px;
  animation: roundtime calc(var(--duration) * 1s) linear forwards;
  transform-origin: left center;
}
@keyframes roundtime {
  to {
    transform: scaleX(0);
  }
}
.atmequiz_game_que_card_timer_wrapper .atmequiz_game_que_card_timer_time {
  border-radius: 50px;
  background: #3b4559;
  border: 6px solid #3b4559;
  width: 72px;
  height: 72px;
  left: 0px;
  top: 0px;
}
.atmequiz_game_que_card_body .atmequiz_game_que_card_content_wrap {
  background: #3b4559;
  border-radius: 16px;
  padding: 24px 8px;
  min-height: 240px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.atmequiz_game_option_box {
  width: 100%;
}
.atmequiz_game_que_card_content_wrap .atmequiz_game_quiz_card_que_number {
  font-size: 12px;
  font-weight: 400;
  width: 35%;
  height: 24px;
  margin: 20px auto 0;
}
.atmequiz_game_que_card_number_text .atmequiz_game_que_card_total_que_num {
  padding-left: 4px;
  font-weight: 700;
  font-size: 14px;
}
.atmequiz_game_que_card_content_wrap .atmequiz_game_quize_card_que_title {
  padding: 4px 0 12px;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  width: calc(100% - 16px);
  min-height: 58px;
}
.atmequiz_game_que_card_content_wrap
  .atmequiz_game_que_card_lifeline_text_button {
  position: absolute;
  border-radius: 50px;
  border: 4px solid #172031;
  background: #ffb540;
  padding: 6px 24px;
  text-transform: uppercase;
  font-size: 14px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--white-color);
  bottom: 30px;
}
.atmequiz_game_que_card_footer .atmequiz_game_que_card_score {
  font-weight: 400;
  color: rgba(var(--white-color-rgb), 0.6);
  font-size: 18px;
}
.atmequiz_game_que_card_score .atmequiz_game_que_card_score_number {
  color: var(--white-color);
  font-weight: 700;
}
.atmequiz_game_que_page_card .atmequiz_game_que_card_footer {
  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  position: relative;
  top: 14px;
}
.atmequiz_game_que_card_content_wrap
  .atmequiz_game_quize_card_lifeline_wrapper {
  width: 100%;
}
li.atmequiz_game_quize_lifeline_card_item {
  margin-right: 8px;
  width: calc(25% - 16px);
  margin-bottom: 12px;
  border-radius: 8px;
}
.atmequiz_game_quize_lifeline_card_item .atmequiz_game_quize_lifeline_card_img {
  border-radius: 16px;
  border: 2px solid #4c51af;
  background: #4c51af;
  min-width: 86px;
  min-height: 84px;
  margin-bottom: 10px;
}
.atmequiz_game_quize_lifeline_card_item
  .atmequiz_game_quize_lifeline_card_text {
  font-size: 11px;
}
.atmequiz_game_grid .atmequiz_game_popup_box {
  width: calc(100% - 60px);
  height: auto;
  padding: 30px;
  position: absolute;
  border-radius: 16px;
  min-height: 300px;
  top: 25%;
  margin: 0 30px;
  text-align: center;
  background: #3b4559 url("../src/images/cardBG.svg") no-repeat top 0 left -6px;
  position: relative;
}
.atmequiz_game_popup_content .atmequiz_game_oops_text {
  font-size: 34px;
  color: #ff5050;
}
.atmequiz_game_popup_content .atmequiz_game_desc_text {
  font-size: 16px;
  line-height: 18px;
  color: rgba(var(--white-color-rgb), 0.64);
}
.atmequiz_game_button_desc_div .atmequiz_game_desc_text {
  margin-top: 24px;
}
.atmequiz_game_popup_content .atmequiz_game_button_desc_div {
  margin-top: 20px;
}
.atmequiz_game_popup_content .atmequiz_game_button_row {
  height: 52px;
  width: 200px;
  font-size: 22px;
  padding: 0;
  float: right;
  border-radius: 50px;
  margin-top: 0;
  background: #ffb540;
  outline: none;
  color: #fff;
  border: none;
  font-weight: 700;
}
.atmequiz_game_popup_body .atmequiz_game_close_icon {
  background: transparent;
  top: 6px;
  right: 6px;
}
.atmequiz_game_popup_content .atmequiz_game_time_text {
  font-size: 22px;
  color: #fff;
  line-height: 24px;
}
.atmequiz_game_popup_content .atmequiz_game_button_row img {
  margin-right: 8px;
  margin-left: -6px;
  max-width: 30px;
  min-height: 30px;
}
.atmequiz_game_played_score_grid .atmequiz_game_played_score_game_name {
  font-size: 14px;
  color: rgba(var(--white-color-rgb), 0.64);
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
}
.atmequiz_game_played_score_grid .atmequiz_game_played_score_desc {
  font-size: 18px;
  line-height: 22px;
}
.atmequiz_game_played_score_grid .atmequiz_game_played_score_wrapper {
  background: #3b4559 url("../src/images/cardBG.svg") no-repeat top 0 left -6px;
  border-radius: 16px;
  padding: 0;
  min-height: 320px;
  background-size: contain;
  overflow: hidden;
}
.atmequiz_game_played_score_wrapper .atmequiz_game_played_score_img {
  max-width: 150px;
  min-height: 150px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.atmequiz_game_played_score_wrapper .atmequiz_game_played_score_img img {
  max-width: 170px;
}
.atmequiz_game_played_score_content_box
  .atmequiz_game_played_score_time_over_text {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(var(--white-color-rgb), 0.74);
}
.atmequiz_game_played_score_content_box .atmequiz_game_played_score_text {
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
}
.atmequiz_game_played_score_text .atmequiz_game_played_score_number {
  color: #5fff9f;
  vertical-align: middle;
}
.atmequiz_game_played_score_content_box
  .atmequiz_game_played_score_content_desc {
  font-size: 13px;
}
.atmequiz_game_played_score_content_box .atmequiz_game_played_score_coin_img {
  margin-left: 4px;
  vertical-align: middle;
  animation: coin_opacity 1.5s ease-in-out infinite alternate;
}
@keyframes coin_opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.atmequiz_game_played_score_box .atmequiz_game_played_score_info_wrap {
  width: 100%;
  margin-top: 30px;
  border-top: 1px solid #4c51af;
}
.atmequiz_game_played_score_info_box li {
  padding: 0;
  background: #172031;
  width: 25%;
  min-height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.atmequiz_game_played_score_info_box li:not(:last-child) {
  border-right: 1px solid #4c51af;
}
.atmequiz_game_played_score_info_item .atmequiz_game_played_score_info_number {
  font-size: 24px;
  font-weight: 700;
  line-height: 18px;
}
.atmequiz_game_played_score_info_item
  .atmequiz_game_played_score_info_subtitle {
  font-size: 11px;
  font-weight: 400;
  margin-top: 8px;
}
.atmequiz_game_played_score_grid .atmequiz_game_played_score_join_claim_text {
  color: rgba(var(--white-color-rgb), 0.74);
  font-size: 16px;
  font-weight: 700;
  margin-top: 16px;
}
.atmequiz_game_played_score_grid
  .atmequiz_game_played_score_more_quiz_subtitle {
  margin-bottom: 16px;
  font-size: 20px;
}
.atmequiz_game_played_score_grid
  .atmequiz_game_played_score_game_name_desc_row {
  margin: 16px 0;
}
.score_button_after {
  position: relative;
  overflow: hidden;
}
.atmequiz_game_free_coin_bottom_buttom {
  position: absolute;
  bottom: 8px;
  right: 8px;
  filter: drop-shadow(0 0 28px #000);
  max-width: 76px;
}

.atmequiz_game_footer {
  /* background: #172031; */
  max-width: 428px;
  margin: auto;
  padding: 24px 0 0;
}
.atmequiz_game_footer_inner .atmequiz_game_footer_wrap {
  text-align: center;
  padding-top: 12px;
}
.atmequiz_game_footer_copiright_content .atmequiz_game_footer_copiright_text p {
  color: #f5f6ff;
  font-size: 12px;
  font-weight: 400;
}
.bonusModal_wrapper__IpNgp {
  background: #3b4559;
  position: absolute;
  top: 25%;
  margin: 0 15px;
}
.bonusModal_report__kFJSI {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2002;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  width: 428px;
  overflow: hidden;
  background: 0 0;
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  background-color: #172031;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 30px;
  margin-left: -13px;
}
.sidenav.open {
  width: 390px;
}

.sidenav .closebtn {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 36px;
  color: white;
  cursor: pointer;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 16px;
  color: #ffffffba;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.header_welcomeProfile__UhPxb {
  display: flex;
  flex-wrap: wrap;
  background: #172031;
  margin-top: -8px;
  padding: 20px 24px;
  flex-direction: column;
  text-align: center;
}
.header_profile__hrbFH {
  display: flex;
}
.header_profilePic__qVEKN {
  position: relative;
}
.header_userDetails__SV2aA {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
}
.header_welcomeProfile__UhPxb h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  text-align: left;
}
.header_hide__bdeRc {
  display: none;
}
.header_welcomeProfile__UhPxb h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  display: flex;
  text-align: left;
}
.header_contenteditable__Kx6aE {
  min-width: 150px;
  cursor: pointer;
  border: 1px solid #172031;
  margin-right: 4px;
  max-height: 20px;
  overflow: hidden;
}
.header_welcomeProfile__UhPxb p {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
}
.header_btn__2sj_6.header_btnSmall__j7DBd {
  height: 25px;
  width: 80px;
  font-size: 13px;
  padding: 3px 8px;
  font-weight: 600;
  text-shadow: 0 1px 0 #fc8c1b;
  line-height: 20px;
  text-transform: uppercase;
  margin-top: 4px;
  border-radius: 50px;
}
.header_shine__zk9yn {
  position: relative;
  overflow: hidden;
}
.header_btn__2sj_6 {
  background: #fc8c1b;
  padding: 16px;
  height: 56px;
  width: 100%;
  line-height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.header_menuItems__PRv4k {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #3b4559;
}
.header_menuItems__PRv4k ul li a i.header_icon__cvIi5 {
  margin-right: 16px;
  display: inline-block;
  background: #172031;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  text-align: center;
  vertical-align: middle;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_menuItems__PRv4k ul li a {
  width: 100%;
  padding: 10px 24px;
  border-bottom: 0 solid #3b4559;
  color: #ffffffba;
  display: flex;
  align-items: center;
}
.header_connectWithUs__SSiTK {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin: 24px 24px 0;
}
.header_connectWithUs__SSiTK h4 {
  font-size: 14px;
  font-weight: 500;
}
.header_connectWithUs__SSiTK ul li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.header_connectWithUs__SSiTK ul li a i.header_socialIcon__5XfEC {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  padding: 8px;
  border: 1px solid #3b4559;
  border-radius: 8px;
  margin: 12px 16px 8px 0;
  text-align: center;
}
.image_cover {
  max-width: 100%;
  display: inline-block;
  width: 76px;
  border-radius: 16px;
  height: 76px;
  min-width: 76px;
}
.text-success {
  background-color: #92db92 !important;
}

.text-danger {
  background-color: #e17575 !important;
}

.correct-option {
  background-color: #92db92;
}

.incorrect-option {
  background-color: #e17575;
}
.icon_postion {
  margin-bottom: 13px;
}
strong {
  margin-right: 5px;
}
.correct {
  background-color: #92db92;
}

.wrong {
  background-color: #e17575;
}
.price_sections {
  margin-bottom: -20px;
}
.span_section_position {
  margin-left: 6px;
}
.winner_trophy {
  position: absolute;
  max-width: 50% !important;
  margin-bottom: 50px;
}
.winner_trophy2 {
  position: absolute;
  max-width: 90% !important;
  margin-bottom: 50px;
}
.report_desc 
{
  margin-left: 10px;
}
.radio_box
{
  width: 21px;
  height: 25px;
}
.tag_fonts_size 
{
  font-size: 20px !important;
}
label 
{
  display: flex;
}
.alert_message 
{
  background: #9093ce;
  font-size: 18px;
  font-weight: 700;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #172031;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: black;
  padding: 100px;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.claim-button {
  background-color: #ffb540;
  color: #fff;
  border: none;
  padding: 2px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.close-button img {
  width: 20px;
  height: 20px;
}

.flip_image 
{
  animation: flip 3s infinite;
    margin: 30px auto;
    display: block;
}
